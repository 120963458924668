import React, {useContext, useState} from 'react';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {DataContext} from '../../../context/dataContext';
import Processing from '../../../components/Processing/Processing';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {FormGroup} from 'react-bootstrap';
import {getUser} from '../../../utils/constants';

const UploadDiamond = () => {
  const {setLoading: setGlobalLoading} = useContext(DataContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(getUser());
  const formik = useFormik({
    initialValues: {
      uploadExcel: null,
    },
    validationSchema: Yup.object({
      uploadExcel: Yup.mixed()
        .required('Please select an Excel file')
        .test(
          'fileFormat',
          'Unsupported file format. Please select an Excel file.',
          (value) => {
            if (!value) return true;
            return [
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ].includes(value.type);
          },
        ),
    }),
    onSubmit: async (values) => {
      const {uploadExcel} = values;

      const formData = new FormData();
      formData.append('sheet', uploadExcel);
      formData.append('uId', user?._id);
      try {
        setLoading(true);
        setGlobalLoading(true);

        const response = await fetchDataFromAPI(
          'diamond/UploadDiamondExcel',
          'post',
          formData,
          user?.authToken,
        );
        toast.success(response?.message || 'File uploaded successfully', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });

        formik.resetForm();
        setLoading(false);
        setGlobalLoading(false);
        navigate('/get-diamond');
      } catch (error) {
        toast.error(error?.response?.data?.message || 'File upload failed', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });

        setLoading(false);
        setGlobalLoading(false);
        console.error('Error:', error);
      }
    },
  });

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/get-diamond">Diamond</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Upload Diamond</span>
            </li>
          </ol>
        </nav>
      </div>
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="uploadExcel" className="form-label">
                          Select Excel File
                        </label>
                        <FormGroup>
                          <input
                            className={`form-control ${
                              formik.touched.uploadExcel &&
                              formik.errors.uploadExcel
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="file"
                            name="uploadExcel"
                            accept=".xls,.xlsx"
                            id="uploadExcel"
                            onChange={(e) =>
                              formik.setFieldValue(
                                'uploadExcel',
                                e.target.files[0],
                              )
                            }
                          />
                        </FormGroup>
                        {formik.touched.uploadExcel &&
                          formik.errors.uploadExcel && (
                            <div className="text-danger">
                              {formik.errors.uploadExcel}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={loading}
                        type="submit"
                        label="Upload Diamond"
                        loadingLabel="Uploading..."
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default UploadDiamond;
