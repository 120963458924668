import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {DataContext} from '../../context/dataContext';
import {getUser} from '../../utils/constants';
import {fetchDataFromAPI} from '../../utils/api';
import Processing from '../Processing/Processing';
import CommonButton from '../CommonButton/CommonButton';
import Select from 'react-select';
const AddAdmin = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const [featuresList, setFeaturesList] = useState([]);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      password: '',
      role: 'admin',
      features: [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      phone: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone number is required'),
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
      features: Yup.array()
        .min(1, 'Please select at least one feature')
        .required('Please select features'),
    }),
    onSubmit: (values) => {
      const {first_name, last_name, phone, email, password, role, features} =
        values;

      const body = {
        first_name,
        last_name,
        phone,
        email,
        password,
        role,
        features,
      };

      console.log('Submitted data:', body);

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI('/admin/register', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/super-admin');
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
          });
      } catch (error) {
        console.log('Error in form submission:', error);
        dataContext.setLoading(false);
      }
    },
  });

  useEffect(() => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('/admin/getAllFeature', 'get', '', user?.authToken)
      .then((res) => {
        const transformedFeaturesList =
          res?.data[0]?.features.map((feature) => ({
            value: feature.toLowerCase(), // Use original keys
            label:
              feature.charAt(0).toUpperCase() +
              feature.slice(1).replace(/-/g, ' '),
          })) || [];

        setFeaturesList(transformedFeaturesList);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('Error fetching features list:', error);
        dataContext.setLoading(false);
      });
  }, []);

  const handleFieldChange = (fieldName, e) => {
    formik.setFieldValue(fieldName, e.target.value.trimStart());
  };

  // const handlePhoneChange = (e) => {
  //   const value = e.target.value.replace(/\D/g, "");
  //   if (value?.length > 0 && value?.[0] !== "0") {
  //     formik.setFieldValue("phone", `0${value.trimStart()}`);
  //   } else {
  //     formik.setFieldValue("phone", value.trimStart());
  //   }
  // };
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
    formik.setFieldValue('phone', value.trimStart()); // Set the trimmed numeric value
  };

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/super-admin">Super Admin</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Admin</span>
              </li>
            </ol>
          </nav>
        </div>

        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    <form onSubmit={formik.handleSubmit}>
                      {/* First Name */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="first_name" className="form-label">
                            First Name
                          </label>
                          <input
                            name="first_name"
                            type="text"
                            className="form-control"
                            id="first_name"
                            value={formik.values.first_name}
                            onChange={(e) => handleFieldChange('first_name', e)}
                          />
                          {formik.touched.first_name &&
                            formik.errors.first_name && (
                              <div className="text-danger">
                                {formik.errors.first_name}
                              </div>
                            )}
                        </div>
                      </div>

                      {/* Last Name */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="last_name" className="form-label">
                            Last Name
                          </label>
                          <input
                            name="last_name"
                            type="text"
                            className="form-control"
                            id="last_name"
                            value={formik.values.last_name}
                            onChange={(e) => handleFieldChange('last_name', e)}
                          />
                          {formik.touched.last_name &&
                            formik.errors.last_name && (
                              <div className="text-danger">
                                {formik.errors.last_name}
                              </div>
                            )}
                        </div>
                      </div>

                      {/* Phone */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="phone" className="form-label">
                            Phone
                          </label>
                          <input
                            name="phone"
                            type="text"
                            className="form-control"
                            id="phone"
                            value={formik.values.phone}
                            onChange={handlePhoneChange}
                          />
                          {formik.touched.phone && formik.errors.phone && (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Email */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            id="email"
                            value={formik.values.email}
                            onChange={(e) => handleFieldChange('email', e)}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Password */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            name="password"
                            type="password"
                            className="form-control"
                            id="password"
                            value={formik.values.password}
                            onChange={(e) => handleFieldChange('password', e)}
                          />
                          {formik.touched.password &&
                            formik.errors.password && (
                              <div className="text-danger">
                                {formik.errors.password}
                              </div>
                            )}
                        </div>
                      </div>

                      {/* Features */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="features" className="form-label">
                            Features
                          </label>
                          <Select
                            isMulti
                            name="features"
                            options={featuresList}
                            value={featuresList.filter((feature) =>
                              formik.values.features.includes(feature.value),
                            )}
                            // value={featuresList.filter(feature => formik.values.features.includes(feature.value))}

                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions.map(
                                (option) => option.value,
                              ); // Keep the `value` (e.g., "dashboard")
                              formik.setFieldValue('features', selectedValues);
                            }}
                          />
                          {formik.touched.features &&
                            formik.errors.features && (
                              <div className="text-danger">
                                {formik.errors.features}
                              </div>
                            )}
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Add Admin"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddAdmin;
